/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js
 * - /npm/@hotwired/turbo@7.3.0/dist/turbo.es2017-umd.min.js
 * - /npm/jquery-validation@1.19.3/dist/jquery.validate.min.js
 * - /npm/jquery-validation@1.19.3/dist/additional-methods.min.js
 * - /npm/form-request-submit-polyfill@2.0.0/form-request-submit-polyfill.min.js
 * - /npm/sticky-table-headers@0.1.24/js/jquery.stickytableheaders.min.js
 * - /npm/moment@2.29.4/moment.min.js
 * - /npm/moment-timezone@0.5.43/builds/moment-timezone-with-data.min.js
 * - /npm/hammerjs@2.0.8/hammer.min.js
 * - /npm/vanilla-lazyload@17.8.4/dist/lazyload.min.js
 * - /npm/sweetalert2@11.7.22/dist/sweetalert2.all.min.js
 * - /npm/izitoast@1.4.0/dist/js/iziToast.min.js
 * - /npm/daterangepicker@3.1.0/daterangepicker.min.js
 * - /npm/select2@4.1.0-rc.0/dist/js/select2.min.js
 * - /npm/ion-rangeslider@2.3.1/js/ion.rangeSlider.min.js
 * - /npm/swiper@10.1.0/swiper-bundle.min.js
 * - /npm/@yaireo/tagify@4.17.8/dist/tagify.min.js
 * - /npm/@yaireo/tagify@4.17.8/dist/tagify.polyfills.min.js
 * - /npm/@yaireo/dragsort@1.3.1/dist/dragsort.js
 * - /npm/filepond-plugin-file-validate-type@1.2.8/dist/filepond-plugin-file-validate-type.min.js
 * - /npm/filepond-plugin-image-preview@4.6.11/dist/filepond-plugin-image-preview.min.js
 * - /npm/filepond@4.30.4/dist/filepond.min.js
 * - /npm/chart.js@4.3.3/dist/chart.umd.min.js
 * - /npm/inputmask@5.0.8/dist/inputmask.min.js
 * - /npm/jssocials@1.5.0/dist/jssocials.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
